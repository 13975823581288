import { useEffect, useMemo, useState } from 'react'
import {
  useGetBusinessGoalQuery,
  useGetBusinessProfileQuery,
  useGetMyProfileQuery,
  UserTask,
  UserTaskItemStatus,
  useGetUserTasksQuery,
  UserTaskQuickFilter,
  BusinessUserType,
} from '__generated__/api-types-and-hooks'
import { getTenantId } from 'utils/getTenantId'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import Avatar from 'appAssets/appImages/Avatar.png'
import { otherGoalIds, myOtherTasksGoal } from 'appConfig/data'
import { useFeatureFlag } from 'hooks/userFeatureFlag'

interface UseKanbanBoardDataProps {
  filter: Record<string, (string | number)[] | (string | number)>
  paramUserId?: string
}

export const useKanbanBoardData = ({ filter, paramUserId }: UseKanbanBoardDataProps) => {
  const tenantId = getTenantId()
  const { goalId, clientId } = useParams()
  const isAllTasks = otherGoalIds.includes(goalId)
  const [tasks, setTasks] = useState<UserTask[]>([])
  const userId = useSelector((state: RootState) => state.user.user.id)
  const isFieldViewForMiscPlaysEnabled = useFeatureFlag(
    paramUserId ?? clientId ?? userId,
    'release-field-view-misc-plays'
  )

  // Fetch business profile
  const { data: businessData } = useGetBusinessProfileQuery(
    { id: paramUserId ?? clientId ?? userId },
    { refetchOnWindowFocus: false }
  )

  const { data: businessProfileData } = useGetBusinessProfileQuery(
    {
      id: paramUserId ?? clientId ?? userId,
    },
    { refetchOnWindowFocus: false }
  )

  // Fetch user profile
  const { data: userProfile } = useGetMyProfileQuery(
    { userId: paramUserId ?? clientId ?? userId, tenantId },
    { refetchOnWindowFocus: false }
  )

  // Fetch business goal
  const {
    data: businessGoalData,
    isLoading,
    refetch,
  } = useGetBusinessGoalQuery(
    {
      input: { goalId, userId: paramUserId ?? clientId ?? userId, tenantId, filter },
    },
    { refetchOnWindowFocus: false, enabled: !!filter, cacheTime: 0 }
  )

  //Fetch All tasks if goalID matches
  const { data: otherGoalTasks, refetch: refetchTasks } = useGetUserTasksQuery(
    {
      input: {
        userId: paramUserId ?? clientId ?? userId,
        tenantId,
        filter: {
          ...filter,
          quickFilter:
            goalId === 'all-plays'
              ? UserTaskQuickFilter.AllGoalsTasks
              : UserTaskQuickFilter.NoProjectAssigned,
        },
      },
    },
    {
      enabled: isAllTasks,
    }
  )
  const advisors = businessProfileData?.getBusinessProfile?.advisors

  const assigneeOptions = useMemo(() => {
    if (!businessProfileData?.getBusinessProfile?.users) return []

    const userOptions = businessProfileData.getBusinessProfile.users.map((user) => ({
      value: user.userId,
      label: `${user.user.firstName} ${user.user.lastName}`,
      type: user.type,
      user: user.user,
    }))

    if (advisors?.length) {
      advisors.forEach((advisor) => {
        userOptions.push({
          value: advisor.id,
          label: `${advisor.firstName} ${advisor.lastName}`,
          type: BusinessUserType.Member,
          user: {
            id: advisor.id,
            firstName: advisor.firstName,
            lastName: advisor.lastName,
          },
        })
      })
    }

    return userOptions
  }, [advisors, businessProfileData?.getBusinessProfile?.users])

  // Compute userName from userProfile
  const userName = useMemo(() => {
    return `${userProfile?.getMyProfile.firstName} ${userProfile?.getMyProfile.lastName}`
  }, [userProfile])

  const userFirstName = useMemo(() => {
    return userProfile?.getMyProfile.firstName
  }, [userProfile])

  const userLastName = useMemo(() => {
    return userProfile?.getMyProfile.lastName
  }, [userProfile])

  // Compute advisorsData from userBoAdvisorsData and userName
  const advisorsData = useMemo(() => {
    return [
      ...(advisors?.map((advisor) => ({
        value: advisor?.id,
        label: `${advisor?.firstName ?? ''} ${advisor?.lastName ?? ''}`,
      })) ?? []),
      {
        value: clientId ?? userId,
        label: userName,
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisors, userName])

  // Get logo from businessData
  const logo = useMemo(
    () =>
      businessData?.getBusinessProfile?.logo?.url
        ? businessData?.getBusinessProfile?.logo?.url
        : businessData?.getBusinessProfile?.avatar ?? Avatar,
    [businessData]
  )

  const userAvatar = useMemo(() => businessData?.getBusinessProfile?.avatar, [businessData])

  const isViewFieldButton: boolean = useMemo(
    () => !(isAllTasks && isFieldViewForMiscPlaysEnabled),
    [isFieldViewForMiscPlaysEnabled, isAllTasks]
  )

  // Get hours spent per day from businessData
  const hoursSpentPerDay: number = useMemo(
    () => Math.ceil((businessData?.getBusinessProfile?.hoursSpentPerWeek ?? 40) / 5),
    [businessData]
  )

  // Get plan from businessGoalData
  const plan = useMemo(() => businessGoalData?.getBusinessGoal?.plan, [businessGoalData])

  // Get goal  name form businessGoalData
  const goalName = useMemo(
    () =>
      isAllTasks
        ? myOtherTasksGoal.find((goal) => goal.goalId === goalId)?.name || ''
        : businessGoalData?.getBusinessGoal?.name ?? '',
    // eslint-disable-next-line
    [businessGoalData, myOtherTasksGoal, goalId]
  )

  // Get task plan from plan
  const taskPlan = useMemo(
    () =>
      plan
        ? {
            name: plan.name,
            id: plan.planId,
            planExpectedEndDate: plan.expectedEndDate,
            startDate: plan.startDate,
          }
        : undefined,
    [plan]
  )

  // Get milestones from the plan
  const taskMilestones = useMemo(
    () =>
      plan
        ? plan.milestones?.map((milestone) => ({ name: milestone.name, id: milestone.milestoneId }))
        : undefined,
    [plan]
  )

  // Function to get tasks by status
  const getTasksByStatus = (status: UserTaskItemStatus) =>
    tasks.filter((task: UserTask) => task.status === status)

  // useEffect to set tasks when plan is updated
  useEffect(() => {
    const allOtherTasks = otherGoalTasks?.getUserTasks || []
    filter && plan?.tasks && plan?.tasks?.length > 0
      ? setTasks(filterTasksAfterReOrder(plan.tasks))
      : !isAllTasks
      ? setTasks([])
      : setTasks(filterTasksAfterReOrder(allOtherTasks ?? []))
    // eslint-disable-next-line
  }, [filter, plan, isAllTasks, otherGoalTasks?.getUserTasks])

  return {
    userName,
    userFirstName,
    userLastName,
    advisorsData,
    logo,
    userAvatar,
    plan,
    taskMilestones,
    isLoading,
    userId: clientId ?? userId,
    tenantId,
    tasks,
    taskPlan,
    goalName,
    goalId,
    hoursSpentPerDay,
    setTasks,
    goalEndDate: businessGoalData?.getBusinessGoal?.expectedEndDate,
    getTasksByStatus,
    refetch: isAllTasks ? refetchTasks : refetch,
    boAdvisors: advisors,
    isViewFieldButton,
    assigneeOptions,
  }
}

// Sort tasks by 'order' in ascending order
const filterTasksAfterReOrder = (tasks) => {
  const allTasks = tasks.sort((a: UserTask, b: UserTask) => {
    if (a.order && b.order) return a.order - b.order
    return 0
  })
  return allTasks
}
